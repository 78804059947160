<template>
  <div class="interaction-checkbox d-flex align-items-center" :class="{'hide-list-video' : !isShowListVideo}">
    <div
        class="intention-item-checkbox"
        v-if="reactionButton.btn1.text"
    >
      <icon-checkbox class="icon icon-checkbox"
                     :color="reactionButton.btn1.checked ? reactionButton.btn1.color : '#999999'"
                     @click.native="changeReactionButton('btn1')"
                     data-compare-report="action"
      >
      </icon-checkbox>
      <span
          class="intention-item-name"
          v-html="reactionButton.btn1.text"
          :style="{ color: reactionButton.btn1.color }"
      >
        </span>
    </div>
    <div
        class="intention-item-checkbox"
        v-if="reactionButton.btn2.text"
    >
      <icon-checkbox class="icon icon-checkbox"
                     :color="reactionButton.btn2.checked ? reactionButton.btn2.color : '#999999'"
                     @click.native="changeReactionButton('btn2')"
                     data-compare-report="action"
      >
      </icon-checkbox>
      <span
          class="intention-item-name"
          v-html="reactionButton.btn2.text"
          :style="{ color: reactionButton.btn2.color }"
      >
        </span>
    </div>
  </div>
</template>
<script>
import IconCheckbox from "@/components/Commons/IconCheckbox.vue";

export default {
  components: {IconCheckbox},
  props: {
    reactionButton: {
      type: Object,
    },
    isShowListVideo: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {

    }
  },
  methods: {
    changeReactionButton(type) {
      let vm = this;
      this.$emit('changeReactionButton', {
        type: type,
        value: !vm.reactionButton[type].checked
      })
    },
    addCheckboxCustomData() {
      const svgElements = this.$el.querySelectorAll('.interaction-checkbox .icon-checkbox svg');

      if (svgElements) {
          svgElements.forEach(svgElement => {
            svgElement.setAttribute('data-compare-report', 'action');
          });
      }
    }
  },
  mounted(){
    this.addCheckboxCustomData();
  }
};
</script>
<style lang="scss" scoped>
.interaction-checkbox {
  position: absolute;
  top: -50px;
  gap: 30px;

  &.hide-list-video {
    left: 200px;
  }
}

.intention-item-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.intention-item-name {
  font-size: 15px;
  line-height: 24px;
  user-select: none;
}
</style>